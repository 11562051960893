import useGet from "api/useGet";
import React, { useEffect } from "react";
import {ContentsSection, BoardCard, PageSubHeader, PageBody} from "component/app/items";
import { Header } from "component/elements/header";
import {BtnItem} from "../../component/basic/btns";
import {useNavigate, useParams} from "react-router-dom";

const BoardDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id

    const boardApi = useGet({
        url:`/board/detail?company_id=${localStorage.getItem("companyId")}&board_id=${id}`,
        loginType:"login"
    });
    const boardData = boardApi?.data?.board;

    function handleResize(){
        var totalSize = 0;
        if (boardApi?.data?.is_board_permission === 1) {
            let subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
                headerSize = document.querySelectorAll(".header")[0].clientHeight,
                pageBtnSize = document.querySelectorAll(".pageBtn")[0].clientHeight,
                viewSize = document.documentElement.clientHeight;

            totalSize = viewSize - (subTitleSize + headerSize + pageBtnSize)
        } else {
            let subTitleSize = document.querySelectorAll(".pageSubHeaderBox")[0].clientHeight,
                headerSize = document.querySelectorAll(".header")[0].clientHeight,
                viewSize = document.documentElement.clientHeight;

            totalSize = viewSize - (subTitleSize + headerSize)
        }

        document.querySelectorAll(".pageBody")[0].style.height = `${totalSize}px`;
        //document.querySelectorAll(".boardCardBox")[0].style.height = `${totalSize - selectYearBoxSize}px`;
    }

    useEffect(() => {
        handleResize()
    }, [boardData]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                addClass="pageMainBg"
                headTitle="게시글 상세"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents pageMainBg">
                <PageSubHeader
                    contents={<div className="boardTitleBox">
                        {(boardData?.category && boardData?.category !== '일반') && (<div className={`categoryTag ${boardData?.category === '공지' ? 'blueTag' : (boardData?.category === '법정의무교육' ? 'redTag' : '')}`}>{boardData?.category}</div> )}
                        <div className="boardTitle">{boardData?.title}</div>
                        <div className="boardDate">{boardData?.created_at.split(' ')[0]}</div>
                    </div>}
                />
                <PageBody>
                    <div className="cardBox">
                        <div className="boardDetailContents" dangerouslySetInnerHTML={{ __html: boardData?.contents?.replace(/\n/g, '<br/>') }} />
                        {(boardApi?.data?.board_files?.length > 0 && boardApi?.data?.board_files?.map((file) => (
                            <div className="fileBox">
                                <div className="fileInfo">
                                    <div className="fileName">{file?.url?.split('/').pop()}</div>
                                    <div className="fileSize">{(file?.size / 1024 / 1024).toFixed(2)}mb</div>
                                </div>
                                <a href={`${file?.url}`} download={true} target="_blank">
                                    <img className="icon" src="/assets/images/icon/download.svg"/>
                                </a>
                            </div>
                        )))}
                    </div>
                </PageBody>
                {boardApi?.data?.is_board_permission === 1 && (
                    <BtnItem
                        addClass=""
                        contents={"수정하기"} // TODO: 권한 체크 필요
                        disabled={false}
                        func={() => {navigate(`/board/modify/${id}`)}}
                    />
                )}
            </ContentsSection>
        </>
    );
};

export default BoardDetail;